import React, {
  useState, useCallback, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import Img from 'next/image';
import clsx from 'clsx';
import { MenuIcon, ChevronDownIcon } from '@heroicons/react/outline';
import dynamic from 'next/dynamic';

import {
  SHOP_PAGE_PATHNAME,
  SHOP_INFANTE_PAGE_PATHNAME,
} from '../../lib/CONST';
import {
  ROPA,
  ACCESORIOS,
  ITEM_ONLINE_INFANTE_FILTER_CATEGORIA,
} from '../../lib/filterLabels';
import styles from './subHeader.module.css';
import ButtonRegistrate from './ButtonRegistrate';
import ButtonIngresa from './ButtonIngresa';
import ButtonSalir from './ButtonSalir';

import imgLogoSinTexto from '../../../public/images/logo-sin-texto.png';
import useCurrentUser from '../../features/auth/hooks/useCurrentUser';

const ShoppingCartMenu = dynamic(() => import('../ShoppingCartMenu'));
const Sidebar = dynamic(() => import('./Sidebar'));
const MenuDropDown = dynamic(() => import('./MenuDropDown'));

const isUser = [
  {
    name: 'Mi Perfil',
    url: '/perfil/historial-de-puntos',
  },
  {
    name: 'Salir',
    Component: ButtonSalir,
  },
];
const isNoUser = [
  {
    name: 'Regístrate',
    Component: ButtonRegistrate,
  },
  {
    name: 'Ingresa',
    Component: ButtonIngresa,
  },
];
const pages = [
  {
    name: 'Sobre nosotras',
    url: '/sobre-nosotras',
  },
  {
    name: '¿Cómo funciona?',
    url: '/como-funciona',
  },
  {
    name: 'Contacto',
    url: '/contacto',
  },
];

const badgeClasses = `
  absolute inline-flex items-center justify-center
  px-2 py-1
  text-xs font-bold leading-none text-white
  bg-primaryDarken rounded-full
  -translate-x-7 -translate-y-3.5
`;

const classes2 = {
  menuButton: 'cursor-pointer py-3 px-[2px] basis-1/6 lg:basis-1/5 lg:px-1',
  leftLauncher: 'basis-1/3 lg:1/4',
  rebajasLink: 'text-center border-l border-l-solid border-l-bgSecondary',
};

const SubHeader = ({ header }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [mujerList, setMujerList] = useState({
    anchorEl: null,
    hover: false,
  });
  const [infantesList, setInfantesList] = useState({
    anchorEl: null,
    hover: false,
  });
  const handleMujerList = useCallback((value, event) => {
    const anchorEl = event.currentTarget;
    setTimeout(
      () => {
        setMujerList({
          hover: value,
          anchorEl,
        });
      },
      value ? 0 : 100,
    );
  }, []);
  const handleInfantesList = (value, event) => {
    const anchorEl = event.currentTarget;
    setTimeout(
      () => {
        setInfantesList({
          hover: value,
          anchorEl,
        });
      },
      value ? 0 : 100,
    );
  };
  const currUser = useCurrentUser();
  const [activePage, setActivePage] = useState({});

  const pathnameOut = typeof window !== 'undefined' ? window.location.pathname : '';
  useEffect(() => {
    [
      'shop/lo-ultimo',
      'shop/popular',
      'shop/donar-para-ayudar',
      'sobre-nosotras',
      'como-funciona',
      'shop/sale',
      'shop-ninxs/sale',
    ].forEach((path) => {
      const { pathname } = window.location;
      if (pathname.includes(path)) {
        setActivePage({ [path]: true });
      }
    });
  }, [pathnameOut]);

  return (
    <>
      <div
        className={`
          w-full pr-0
          ${header ? 'pr-4' : ''}
          border-b border-solid border-bgSecondary
          subheader
        `}
      >
        <div className="flex justify-between items-start">
          <div className="flex flex-wrap w-11/12">
            <div className="m-0 box-border self-center w-4/12 md:w-3/12">
              <div className={`grid ${!header ? 'grid-cols-3' : 'grid-cols-2'}`}>
                {!header && (
                  <div className="pt-1 place-self-center">
                    <Link href="/" passHref>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a>
                        <Img
                          src={imgLogoSinTexto}
                          width={65}
                          height={(65 * 358) / 850}
                          layout="fixed"
                          alt="Logo de Las Traperas"
                        />
                      </a>
                    </Link>
                  </div>
                )}
                <div
                  className={clsx({
                    [classes2.menuButton]: true,
                    'text-center': true,
                    [classes2.leftLauncher]: true,
                    'border-l border-l-bgSecondary': !header,
                  })}
                >
                  <div
                    className={styles.decoratedButton}
                    onMouseEnter={(e) => handleMujerList(true, e)}
                    onMouseLeave={(e) => handleMujerList(false, e)}
                    data-testid="subheader-button-mujer"
                  >
                    Mujer
                    <ChevronDownIcon className="h-5 w-5 inline" />
                  </div>
                </div>
                <div
                  className={`text-center ${classes2.menuButton} ${classes2.leftLauncher}`}
                >
                  <div
                    className={styles.decoratedButton}
                    onMouseEnter={(e) => handleInfantesList(true, e)}
                    onMouseLeave={(e) => handleInfantesList(false, e)}
                    data-testid="subheader-button-ninxs"
                  >
                    Niñ
                    {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                    <span className={clsx([styles.heartEmoji, styles.red])} />s
                    {/* Niñ💚s */}
                    <ChevronDownIcon className="h-5 w-5 inline" />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-8/12 md:w-9/12 m-0 box-border  self-center">
              <div className="flex justify-center items-center border-x border-x-solid border-x-bgSecondary">
                <div
                  className={clsx({
                    [classes2.menuButton]: true,
                    'text-center': true,
                    [styles.decoratedLink]: true,
                    [styles.active]: activePage['shop/lo-ultimo'],
                  })}
                >
                  <Link
                    href="/shop/lo-ultimo"
                    className={classes2.menuButton}
                  >
                    Lo Último
                  </Link>
                </div>
                <div
                  className={clsx({
                    [classes2.menuButton]: true,
                    'text-center': true,
                    [styles.decoratedLink]: true,
                    [styles.active]: activePage['shop/popular'],
                  })}
                >
                  <Link
                    href="/shop/popular"
                    className={classes2.menuButton}
                  >
                    Populares
                  </Link>
                </div>
                <div
                  className={clsx({
                    [classes2.menuButton]: true,
                    [classes2.rebajasLink]: true,
                    [styles.decoratedLink]: true,
                    [styles.active]: activePage['shop/sale'],
                  })}
                >
                  <Link
                    href="/shop/sale"
                    className={classes2.menuButton}
                    passHref
                  >
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a>
                      <span className={badgeClasses}>SALE</span>
                      REBAJAS
                    </a>
                  </Link>
                </div>
                <div
                  className={clsx({
                    [classes2.menuButton]: true,
                    [classes2.rebajasLink]: true,
                    [styles.decoratedLink]: true,
                    [styles.active]: activePage['shop-ninxs/sale'],
                  })}
                >
                  <Link
                    href="/shop-ninxs/sale"
                    className={classes2.menuButton}
                    passHref
                  >
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a>
                      <span className={badgeClasses}>SALE</span>
                      {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                      NIÑ<span className={clsx([styles.heartEmoji, styles.red])} />S
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="w-1/12 m-0 box-border self-center">
            <div className="flex justify-end items-end">
              <ShoppingCartMenu id="shoppingCartSubheader" />
              {!header && (
                <button
                  type="button"
                  className="w-12 h-12"
                  onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                >
                  <span className="sr-only">Abrir menú</span>
                  <MenuIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <MenuDropDown
        categories={[ROPA, ACCESORIOS]}
        titles={['Ropa', 'Accesorios']}
        listProps={mujerList}
        pathname={SHOP_PAGE_PATHNAME}
      />
      <MenuDropDown
        categories={[Object.keys(ITEM_ONLINE_INFANTE_FILTER_CATEGORIA)]}
        listProps={infantesList}
        pathname={SHOP_INFANTE_PAGE_PATHNAME}
      />
      {isSidebarOpen && (
        <Sidebar
          navItems={!currUser ? pages.concat(isNoUser) : pages.concat(isUser)}
          close={() => setIsSidebarOpen(false)}
          socialIcons={false}
          activePage={activePage}
        />
      )}
    </>
  );
};

SubHeader.propTypes = {
  header: PropTypes.bool,
};

SubHeader.defaultProps = {
  header: true,
};

export default SubHeader;
